// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InventoryDetails.css */
.inventory-details {
    padding: 20px;
}

.inventory-details h4 {
    margin-bottom: 20px;
}

.inventory-details .search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.inventory-details table {
    width: 100%;
    border-collapse: collapse;
}

.inventory-details th, .inventory-details td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.inventory-details th {
    background-color: #f2f2f2;
}

.item-thumbnail {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
/* InventoryDetails.css */
r.inventory-details {
    padding: 20px;
}

.inventory-details h4 {
    margin-bottom: 20px;
}

.inventory-details .input-field {
    margin-bottom: 20px;
}

.inventory-details table {
    width: 100%;
    border-collapse: collapse;
}

.inventory-details th, .inventory-details td {
    padding: 8px;
    text-align: center;
}

.inventory-details th {
    background-color: #f2f2f2;
}

.item-thumbnail {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.zero-balance {
    background-color: #fff9c4; /* Light yellow */
}
`, "",{"version":3,"sources":["webpack://./src/components/InventoryDetailsPage/InventoryDetails.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;AACA,yBAAyB;AACzB;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,yBAAyB,EAAE,iBAAiB;AAChD","sourcesContent":["/* InventoryDetails.css */\n.inventory-details {\n    padding: 20px;\n}\n\n.inventory-details h4 {\n    margin-bottom: 20px;\n}\n\n.inventory-details .search-bar {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n}\n\n.inventory-details table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.inventory-details th, .inventory-details td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\n.inventory-details th {\n    background-color: #f2f2f2;\n}\n\n.item-thumbnail {\n    width: 300px;\n    height: 300px;\n    object-fit: cover;\n}\n/* InventoryDetails.css */\nr.inventory-details {\n    padding: 20px;\n}\n\n.inventory-details h4 {\n    margin-bottom: 20px;\n}\n\n.inventory-details .input-field {\n    margin-bottom: 20px;\n}\n\n.inventory-details table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.inventory-details th, .inventory-details td {\n    padding: 8px;\n    text-align: center;\n}\n\n.inventory-details th {\n    background-color: #f2f2f2;\n}\n\n.item-thumbnail {\n    width: 300px;\n    height: 300px;\n    object-fit: cover;\n}\n.zero-balance {\n    background-color: #fff9c4; /* Light yellow */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
