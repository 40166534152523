// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-section {
    max-width: 100%;
    overflow-x: auto;
    padding: 10px;
    border-radius: 15px;
    border: 2px solid #ddd;
    background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemSelector/ItemSelector.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":[".image-section {\n    max-width: 100%;\n    overflow-x: auto;\n    padding: 10px;\n    border-radius: 15px;\n    border: 2px solid #ddd;\n    background-color: #f9f9f9;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
