import React from 'react';
import './MaterializeCard.css'; // Import the CSS file
import IconHyperlink from './../../utilities/IconHyperlink';
import { IMAGE_BASE_URL } from '../../config/constants';

const MaterializeCard = ({ title, image, itemcode, quantity, boxno, price, sellingprice, balancequantity, totalsold, editClick, deleteClick, deleteIcon, editIcon, addToCart }) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(price);
  };

  return (
    <div className="card">
      <div className="row">
        {editIcon && editClick && (
          <div className="col s9">
            <IconHyperlink
              href="#"
              onClick={editClick}
              icon={editIcon}
              iconSize="small"
              additionalClasses=""
            />
          </div>
        )}

        {deleteIcon && deleteClick && (
          <div className="col s2">
            <IconHyperlink
              href="#"
              onClick={deleteClick}
              icon={deleteIcon}
              iconSize="small"
              additionalClasses=""
            />
          </div>
        )}
        {image && (
          <div className="col s12 center-align">
            <img src={IMAGE_BASE_URL + image} alt={title} className="card-image-fixed" />
          </div>
        )}
        <div className="leftspace">
          <ul >

            {itemcode}

            {boxno && <li>Box no: {boxno}</li>}
            {price && <li>P.P: {formatPrice(price)}</li>}
            {sellingprice && <li>S.P: {formatPrice(sellingprice)}</li>}
            {quantity && <li>Stocked: {quantity}</li>}
            {balancequantity && <li>Quantity left: {balancequantity}</li>}
            {totalsold && <li>Total sold: {totalsold}</li>}
   
          </ul>
          {addToCart && (<button className="btn checkout-btn" onClick={() => addToCart(itemcode)}>
            Add to Cart
          </button>)}
        </div>
      </div>
    </div>
  );
};

export default MaterializeCard;
