import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';


const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Define the set of allowed emails
  const allowedEmails = ['rcp.rahul@gmail.com', 'ammujgd@gmail.com', 'illolam.anjana@gmail.com'];

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user && allowedEmails.includes(user.email)) {
        setUser(user); // Set the user if their email is allowed
      } else {
        if (user) {
          // Log out the user if they are not allowed
          firebase.auth().signOut();
        }
        setUser(null); // Set user to null if not authenticated or not allowed
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // You can show a loading spinner here
  }

  // Only render children if the user is authenticated and their email is allowed
  return user ? children : <Navigate to="/" />;
};

export default PrivateRoute;
