// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipment-completed {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.shipment-item {
    display: flex;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.shipment-column {
    flex: 1;
    padding: 10px;
}

.btn {
    margin-top: 10px;
}

.enlarged-image {
    width: 80%;
    height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShipmentPage/shipment.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,OAAO;IACP,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":[".shipment-completed {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.shipment-item {\n    display: flex;\n    width: 100%;\n    border: 1px solid #ccc;\n    padding: 10px;\n    margin-bottom: 10px;\n}\n\n.shipment-column {\n    flex: 1;\n    padding: 10px;\n}\n\n.btn {\n    margin-top: 10px;\n}\n\n.enlarged-image {\n    width: 80%;\n    height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
