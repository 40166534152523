import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InventoryDetails.css';
import { Link } from 'react-router-dom';
import MaterializeCard from './../ItemCard/MaterializeCard';
import GooglePayButton from './../GooglePayComponent/GooglePayButton';
import { API_BASE_URL } from '../../config/constants';

const InventoryDetails = () => {
    const [items, setItems] = useState([]);
    const [itemSalesQuantities, setItemSalesQuantities] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [boxNoFilter, setBoxNoFilter] = useState('');
    const [balanceFilter, setBalanceFilter] = useState('');
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [buyingPriceMin, setBuyingPriceMin] = useState('');
    const [buyingPriceMax, setBuyingPriceMax] = useState('');
    const [sellingPriceMin, setSellingPriceMin] = useState('');
    const [sellingPriceMax, setSellingPriceMax] = useState('');
    let purchasePriceTotal = 0;
    let quantityTotal = 0;
    let purchasePriceLeftTotal = 0;
    let quantityLeftTotal = 0;

    useEffect(() => {
        axios.get(`${API_BASE_URL}/items`).then(response => {
            setItems(response.data || []);
            fetchItemSalesQuantity();
        });
    }, []);

    const fetchItemSalesQuantity = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/sales`);
            const salesData = response.data || [];
            const itemQuantities = {};

            salesData.forEach(sale => {
                Object.keys(sale.items).forEach(itemId => {
                    if (!itemQuantities[itemId]) {
                        itemQuantities[itemId] = 0;
                    }
                    itemQuantities[itemId] += sale.items[itemId][0]; // Assuming the quantity is the first element
                });
            });

            setItemSalesQuantities(itemQuantities);
        } catch (error) {
            console.error('Failed to fetch sales data', error);
        }
    };

    const addToCart = (itemcode) => {
        const item = items.find(item => item.code === itemcode);
        if (item) {
            setCartItems([...cartItems, item]);
        }
    };

    const handleSearchChange = (e) => setSearchTerm(e.target.value);
    const handleBoxNoFilterChange = (e) => setBoxNoFilter(e.target.value);
    const handleBalanceFilterChange = (e) => setBalanceFilter(e.target.value);
    const handleBuyingPriceMinChange = (e) => setBuyingPriceMin(e.target.value);
    const handleBuyingPriceMaxChange = (e) => setBuyingPriceMax(e.target.value);
    const handleSellingPriceMinChange = (e) => setSellingPriceMin(e.target.value);
    const handleSellingPriceMaxChange = (e) => setSellingPriceMax(e.target.value);
    const toggleAdditionalFilters = () => setShowAdditionalFilters(!showAdditionalFilters);
    const resetFilters = () => {
        setSearchTerm('');
        setBoxNoFilter('');
        setBalanceFilter('');
        setBuyingPriceMin('');
        setBuyingPriceMax('');
        setSellingPriceMin('');
        setSellingPriceMax('');
        setShowAdditionalFilters(false);
    };

    const filteredItems = items.filter(item => {
        const originalInventory = parseInt(item.quantity);
        const reducedInventory = itemSalesQuantities[item.inventoryid] || 0;
        const balance = originalInventory - reducedInventory;

        // Allow partial matching for Item Code
        const matchesSearch = searchTerm === '' || item.code.toLowerCase().includes(searchTerm.toLowerCase());

        // Keep exact matching for Box No
        const matchesBoxNo = boxNoFilter === '' || item.boxno.toString() === boxNoFilter;

        const balanceFilterNumber = balanceFilter === '' ? NaN : parseInt(balanceFilter);
        const matchesBalance = isNaN(balanceFilterNumber) || balance === balanceFilterNumber;

        const price = item.price || 0;
        const sellingprice = item.sellingprice || 0;

        const matchesBuyingPriceMin = buyingPriceMin === '' || price >= parseInt(buyingPriceMin);
        const matchesBuyingPriceMax = buyingPriceMax === '' || price <= parseInt(buyingPriceMax);

        const matchesSellingPriceMin = sellingPriceMin === '' || sellingprice >= parseInt(sellingPriceMin);
        const matchesSellingPriceMax = sellingPriceMax === '' || sellingprice <= parseInt(sellingPriceMax);

        return matchesSearch && matchesBoxNo && matchesBalance && matchesBuyingPriceMin && matchesBuyingPriceMax && matchesSellingPriceMin && matchesSellingPriceMax;
    });

    return (
        <div className="inventory-details">
            <h4>Inventory Details</h4>
            <a href="upi://pay?pa=your-upi-id@bank&pn=YourName&am=100.00&cu=INR">Pay ₹100 via UPI</a>
            <div className="row">
                {/* Existing filter inputs */}
                <div className="input-field col s12 l4">
                    <input
                        type="text"
                        id="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="validate"
                    />
                    <label htmlFor="search">Item Code</label>
                </div>
                <div className="input-field col s12 l4">
                    <input
                        type="text"
                        id="boxno-filter"
                        value={boxNoFilter}
                        onChange={handleBoxNoFilterChange}
                        className="validate"
                    />
                    <label htmlFor="boxno-filter">Box No</label>
                </div>
                <div className="input-field col s12 l4">
                    <input
                        type="text"
                        id="balance-filter"
                        value={balanceFilter}
                        onChange={handleBalanceFilterChange}
                        className="validate"
                    />
                    <label htmlFor="balance-filter">Q left</label>
                </div>
            </div>
            <div className="row">
                <Link to="#" onClick={toggleAdditionalFilters} className="additional-filters-link">
                    {showAdditionalFilters ? 'Hide Additional Filters' : 'Show Additional Filters'}
                </Link>
            </div>
            {showAdditionalFilters && (
                <div className="row additional-filters">
                    <div className="input-field col s6">
                        <input
                            type="text"
                            id="buying-price-min"
                            value={buyingPriceMin}
                            onChange={handleBuyingPriceMinChange}
                            className="validate"
                        />
                        <label htmlFor="buying-price-min">Min BP</label>
                    </div>
                    <div className="input-field col s6">
                        <input
                            type="text"
                            id="buying-price-max"
                            value={buyingPriceMax}
                            onChange={handleBuyingPriceMaxChange}
                            className="validate"
                        />
                        <label htmlFor="buying-price-max">Max BP</label>
                    </div>
                    <div className="input-field col s6">
                        <input
                            type="text"
                            id="selling-price-min"
                            value={sellingPriceMin}
                            onChange={handleSellingPriceMinChange}
                            className="validate"
                        />
                        <label htmlFor="selling-price-min">Min SP</label>
                    </div>
                    <div className="input-field col s6">
                        <input
                            type="text"
                            id="selling-price-max"
                            value={sellingPriceMax}
                            onChange={handleSellingPriceMaxChange}
                            className="validate"
                        />
                        <label htmlFor="selling-price-max">Max SP</label>
                    </div>
                </div>
            )}
            <div className="row">
                <button className="btn  waves-light" onClick={resetFilters}>Reset Filters</button>
            </div>

            {/* Inventory Summary */}
            {filteredItems.map(item => {
                const originalInventory = parseInt(item.quantity);
                const reducedInventory = itemSalesQuantities[item.inventoryid] || 0;
                const balance = originalInventory - reducedInventory;
                const price = item.price || 0;
                const sellingprice = item.sellingprice || 0;

                quantityTotal += parseInt(item.quantity);
                purchasePriceTotal += (parseInt(item.quantity) * parseFloat(price));
                quantityLeftTotal += parseInt(balance);
                purchasePriceLeftTotal += (parseInt(balance) * parseFloat(price));
            })}
            <div className="row">
                Quantity Total: {quantityTotal}<br />
                Total Money Spend: {purchasePriceTotal}<br />
                Balance Total: {quantityLeftTotal}<br />
                Need To Sell: {purchasePriceLeftTotal}<br />
            </div>

            {/* Items List */}
            <div className="row">
                {filteredItems.map(item => {
                    const originalInventory = parseInt(item.quantity);
                    const reducedInventory = itemSalesQuantities[item.inventoryid] || 0;
                    const balance = originalInventory - reducedInventory;
                    const price = item.price || 0;
                    const sellingprice = item.sellingprice || 0;

                    return (
                        <div className="col s6 m6 l2" key={item.inventoryid}>
                            <MaterializeCard
                                title={item.code}
                                image={item.image}
                                itemcode={item.code}
                                quantity={originalInventory}
                                boxno={item.boxno}
                                price={price}
                                sellingprice={sellingprice}
                                balancequantity={balance}
                                totalsold={reducedInventory}
                             //   addToCart={addToCart}  // Pass the addToCart function here
                            />
                        </div>
                    );
                })}
            </div>

            {/* My Cart Section */}
            <div className="cart">
                <h4>My Cart</h4>
                {cartItems.map(item => (
                    <div key={item.inventoryid}>
                        {item.code} - {item.sellingprice}
                    </div>
                ))}
                {/* Google Pay Button */}
                {cartItems.length > 0 && <GooglePayButton cartItems={cartItems} />}
            </div>

            <Link to="/" className="btn waves-effect waves-light">Go Back</Link>
        </div>
    );
};

export default InventoryDetails;
