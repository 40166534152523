// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_img {
    width: 200px;
    height: 200px;
  }

  .fixed-action-btn {
    display: none; /* Initially hidden */
    position: fixed;
    top: 20px; /* Position from the top */
    right: 20px; /* Position from the right */
    z-index: 1000;
}

.fixed-action-btn.show {
    display: block; /* Show when the user scrolls */
}

.marginbottom-zero .row{
  margin-bottom:0px
}

.marginbottom-zero .input-field {
  margin-top:8px;
  margin-bottom:8px;
}
.marginbottom-zero .select-wrapper input.select-dropdown{
  font-size:13px
}

.mydropdown .select-wrapper+label{
  top:0px !important;
  font-size: 16px !important;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa,EAAE,qBAAqB;IACpC,eAAe;IACf,SAAS,EAAE,0BAA0B;IACrC,WAAW,EAAE,4BAA4B;IACzC,aAAa;AACjB;;AAEA;IACI,cAAc,EAAE,+BAA+B;AACnD;;AAEA;EACE;AACF;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".logo_img {\n    width: 200px;\n    height: 200px;\n  }\n\n  .fixed-action-btn {\n    display: none; /* Initially hidden */\n    position: fixed;\n    top: 20px; /* Position from the top */\n    right: 20px; /* Position from the right */\n    z-index: 1000;\n}\n\n.fixed-action-btn.show {\n    display: block; /* Show when the user scrolls */\n}\n\n.marginbottom-zero .row{\n  margin-bottom:0px\n}\n\n.marginbottom-zero .input-field {\n  margin-top:8px;\n  margin-bottom:8px;\n}\n.marginbottom-zero .select-wrapper input.select-dropdown{\n  font-size:13px\n}\n\n.mydropdown .select-wrapper+label{\n  top:0px !important;\n  font-size: 16px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
