import React from 'react';
import './ShipmentCard.css';
import DatePicker from '../../utilities/DatePicker';
import MoneyInput from '../../utilities/MoneyInput';
import IconHyperlink from './../../utilities/IconHyperlink';

const ShipmentCard = ({ sale, items, shipmentDetails, handleShipmentChange, handleSubmit, handleRevert, isEditing, handleCheckboxChange, isChecked, index }) => {
  const itemDetails = Object.keys(sale.items).map(itemId => {
    const item = items.find(item => item.inventoryid === itemId);
    return item ? item.code : '';
  }).filter(item => item !== '');

  return (
    <div className="card">
      <div className="row">
        <div className="col s1 mTop35 center-align">
        <strong> {index+1}</strong>
        </div>
        <div className="col s3 mTop35">
            <strong>{sale.name}</strong>
        </div>
        <div className="col s3">
        <MoneyInput
                  label="Price"
                  name="shipmentPrice"
                  value={shipmentDetails[sale.id]?.shipmentPrice || ''}
                  onChange={(e) => handleShipmentChange(e, sale.id)}
                />
        </div>
        <div className="col s3 mTop35">

        <IconHyperlink
              href="#"
              onClick={() => handleSubmit(sale.id)}
              icon="local_shipping"
              iconSize="small"
              additionalClasses=""
            />
        </div>
        <div className="col s2 mTop35">

        <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxChange(sale.id)}
            />
            <span></span>
          </label>
</div>
      </div>
    </div>
  );
};

export default ShipmentCard;
