import React, { useEffect, useRef } from 'react';
import M from 'materialize-css';

const SelectBox = ({ label, name, value, onChange, options }) => {
  const selectRef = useRef(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isMobile) {
      const instance = M.FormSelect.init(selectRef.current);
      return () => {
        if (instance && instance.destroy) {
          instance.destroy();
        }
      };
    }
  }, [value, options, isMobile]);

  return (
    <div className="input-field col s12 mydropdown">
      <select
        ref={selectRef}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={isMobile ? "browser-default" : ""}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={name} className={value ? 'active' : ''}>{label}</label>
    </div>
  );
};

export default SelectBox;
