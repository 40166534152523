import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalBox from '../../utilities/ModalBox';
import M from 'materialize-css';
import moment from 'moment';
import './shipment.css';
import ShipmentCard from './../ShipmentCard/ShipmentCard';
import MaterializeCardWithCarousel from '../../components/MaterializeCardWithCarousel/MaterializeCardWithCarousel';

import { API_BASE_URL } from '../../config/constants';

const ShipmentPage = () => {
    const [pendingSales, setPendingSales] = useState([]);
    const [completedSales, setCompletedSales] = useState([]);
    const [items, setItems] = useState([]);
    const [shipmentDetails, setShipmentDetails] = useState({});
    const [selectedShipments, setSelectedShipments] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null);
    const [modalConfig, setModalConfig] = useState({ visible: false, action: null, title: '', content: '', id: null });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/salespending`)
            .then(response => {
                setPendingSales(response.data);
                initializeShipmentDetails(response.data);
            })
            .catch(error => console.error('Error fetching pending sales:', error));

        axios.get(`${API_BASE_URL}/salescomplete`)
            .then(response => setCompletedSales(response.data))
            .catch(error => console.error('Error fetching completed sales:', error));

        axios.get(`${API_BASE_URL}/items`)
            .then(response => setItems(response.data || []))
            .catch(error => console.error('Error fetching items:', error));

        M.Modal.init(document.querySelectorAll('.modal'));
    }, []);

    useEffect(() => {
        if (selectedSale) {
            const elem = document.getElementById('view-modal');
            if (elem) {
                const instance = M.Modal.init(elem);
                instance.open();
            }
        }
    }, [selectedSale]);

    const initializeShipmentDetails = (salesData) => {
        const initialDetails = {};
        salesData.forEach(sale => {
            if (!shipmentDetails[sale.id]) {
                initialDetails[sale.id] = {
                    shipmentDate: sale.shipmentDate 
                        ? moment(sale.shipmentDate).format('DD/MM/YYYY') 
                        : moment().format('DD/MM/YYYY'),
                    shipmentPrice: sale.shipmentPrice || '',
                    shipmentMethod: sale.shipmentMethod || '',
                    trackingId: sale.trackingId || ''
                };
            }
        });
        setShipmentDetails(initialDetails);
    };

    const handleShipmentChange = (e, id) => {
        const { name, value } = e.target;
        setShipmentDetails(prevDetails => ({
            ...prevDetails,
            [id]: {
                ...prevDetails[id],
                [name]: value
            }
        }));
    };

    const handleCheckboxChange = (id) => {
        setSelectedShipments(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(saleId => saleId !== id)
                : [...prevSelected, id]
        );
    };

    const handleGenerateLabel = () => {
        const selectedDetails = pendingSales.filter(sale => selectedShipments.includes(sale.id));
    
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Shipment Labels</title><style>');
        printWindow.document.write(`
            body {
                font-family: Arial, sans-serif;
                margin: 20px;
                font-size: 18px;
            }
            .label-container {
                display: grid;
                grid-template-columns: repeat(2, 50%);
                column-gap: 20px;
                row-gap: 20px;
                page-break-inside: avoid;
            }
            .label {
                border: 1px solid #000;
                padding: 10px;
                box-sizing: border-box;
                page-break-inside: avoid;
            }
            .from-address {
                font-size: 13px;
                text-align: left;
                margin-bottom: 10px;
                white-space: pre-line;
            }
            .to-address {
                font-size: 24px;
                text-align: left;
                margin-bottom: 10px;
                white-space: pre-line;
                margin-left: 50px;
            }
            .address-line {
                margin: 0;
                padding: 0;
            }
            .label-title {
                font-size: 20px;
                font-weight: bold;
            }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write('<div class="label-container">');
        
        selectedDetails.forEach(sale => {
            printWindow.document.write('<div class="label">');
            
            // FROM Address
            printWindow.document.write('<div class="from-address">');
            printWindow.document.write('<p class="label-title">FROM:</p>');
            printWindow.document.write('<p class="address-line">ANJANA ASOK</p>');
            printWindow.document.write('<p class="address-line">KERA 135, HARISMRITHI LANE,</p>');
            printWindow.document.write('<p class="address-line">KANJIRAMPARA, TRIVANDRUM,</p>');
            printWindow.document.write('<p class="address-line">KERALA, PIN 695030</p>');
            printWindow.document.write('<p class="address-line">PH: 7907448913</p>');
            printWindow.document.write('</div>');
        
            // TO Address (Buyer Details)
            printWindow.document.write('<div class="to-address">');
            printWindow.document.write('<p class="label-title">TO:</p>');
            printWindow.document.write(`<p class="address-line"><strong>${sale.name}</strong></p>`);
            printWindow.document.write(`<p class="address-line">${sale.buyerDetails.replace(/\\n/g, '<br/>')}</p>`);
            printWindow.document.write(`<p class="address-line">PH: ${sale.phoneNumber}</p>`);
            printWindow.document.write('</div>');
            
            printWindow.document.write('</div>');
        });
        
        printWindow.document.write('</div>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };
    
    const handleSubmit = (id) => {
        setModalConfig({
            visible: true,
            action: 'submit',
            title: 'Confirm Shipment Update',
            content: `Are you sure you want to update the shipment details for sale ID ${id}?`,
            id
        });
        openModal();
    };

    const handleRevert = (id) => {
        setModalConfig({
            visible: true,
            action: 'revert',
            title: 'Confirm Revert',
            content: 'Are you sure you want to revert this sale status?',
            id
        });
        openModal();
    };

    const openModal = () => {
        const elem = document.getElementById('confirm-modal');
        if (elem) {
            const instance = M.Modal.getInstance(elem);
            if (instance) {
                instance.open();
            }
        }
    };

    const handleConfirm = () => {
        if (modalConfig.action === 'submit') {
            const shipmentData = shipmentDetails[modalConfig.id];
            if (shipmentData.shipmentDate) {
                const parsedDate = moment(shipmentData.shipmentDate, 'DD/MM/YYYY');
                if (!parsedDate.isValid()) {
                    alert('Invalid shipment date format. Please use DD/MM/YYYY.');
                    return;
                }
                shipmentData.shipmentDate = parsedDate.format('YYYY-MM-DD');
            } else {
                shipmentData.shipmentDate = null;
            }

            axios.put(`${API_BASE_URL}/sales/${modalConfig.id}/shipment`, shipmentData)
                .then(() => {
                    setPendingSales(prevSales => prevSales.filter(sale => sale.id !== modalConfig.id));
                    setCompletedSales(prevSales => [
                        ...prevSales, 
                        {
                            ...pendingSales.find(sale => sale.id === modalConfig.id),
                            ...shipmentData,
                            id: modalConfig.id,
                            salesStatus: 'SD'
                        }
                    ]);
                })
                .catch(error => {
                    console.error('Error updating shipment:', error);
                });
        } else if (modalConfig.action === 'revert') {
            axios.put(`${API_BASE_URL}/sales/${modalConfig.id}/status`)
                .then(() => {
                    const saleToRevert = completedSales.find(sale => sale.id === modalConfig.id);
                    setCompletedSales(prevSales => prevSales.filter(sale => sale.id !== modalConfig.id));
                    setPendingSales(prevSales => [
                        ...prevSales,
                        {
                            ...saleToRevert,
                            salesStatus: 'SP'
                        }
                    ]);
                    setShipmentDetails(prevDetails => ({
                        ...prevDetails,
                        [modalConfig.id]: {
                            shipmentDate: saleToRevert.shipmentDate ? moment(saleToRevert.shipmentDate).format('DD/MM/YYYY') : '',
                            shipmentPrice: saleToRevert.shipmentPrice || '',
                            shipmentMethod: saleToRevert.shipmentMethod || '',
                            trackingId: saleToRevert.trackingId || ''
                        }
                    }));
                    setIsEditing(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .catch(error => {
                    console.error('Error reverting sales status:', error);
                });
        }
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const handleCancel = () => {
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const handleViewClick = (e,sale) => {
        e.preventDefault(); 
        setSelectedSale(sale);
    };

    const handleCloseModal = () => {
        const elem = document.getElementById('view-modal');
        if (elem) {
            const instance = M.Modal.getInstance(elem);
            if (instance) {
                instance.close();
            }
        }
        setSelectedSale(null);
    };

    return (
        <div>
            <div className="sales-list row">
                <h5>Shipment List</h5>
                {pendingSales.map((sale, index) => (
                    <div className="col s12" key={sale.id}>
                        <ShipmentCard
                            key={sale.id}
                            sale={sale}
                            items={items}
                            shipmentDetails={shipmentDetails}
                            handleShipmentChange={handleShipmentChange}
                            handleSubmit={handleSubmit}
                            handleRevert={handleRevert}
                            isEditing={isEditing}
                            handleCheckboxChange={handleCheckboxChange}
                            isChecked={selectedShipments.includes(sale.id)}
                            index={index}
                        />
                    </div>
                ))}
            </div>

            <div className="sales-list row right-align">
                <button onClick={handleGenerateLabel} className="btn">Generate Label</button>
            </div>

            <div className="sales-list row">
                <h5>Recently Shipped Items</h5>
                {completedSales.map((sale, index) => (
                    <div className="col s12" key={sale.id}>
                        <div className="card horizontal">
                            <div className="card-stacked">
                                <div className="card-content">
                                    <p><strong>{index + 1}. {sale.name}</strong>: {sale.shipmentPrice}</p>
                              
                                </div>
                                <div className="card-action">
                                <a href="#" onClick={(e) => handleViewClick(e, sale)}>View</a>
                                    <a href="#" onClick={() => handleRevert(sale.id)} className="secondary-content">
                                        <i className="material-icons">edit</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {selectedSale && (
                <div id="view-modal" className="modal">
                    <div className="modal-content">
                        <MaterializeCardWithCarousel
                            items={selectedSale.items}
                            itemsList={items}
                            name={selectedSale.name}
                            buyerdetails={selectedSale.buyerDetails}
                            totalprice={selectedSale.price}
                            phonenumber={selectedSale.phoneNumber}
                            shipmentPrice={selectedSale.shipmentPrice}
                            shipmentDate={moment(selectedSale.shipmentDate).format('DD/MM/YYYY')}
                        />
                    </div>
                    <div className="modal-footer">
                        <button className="modal-close waves-effect waves-green btn-flat" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            )}

            <ModalBox
                id="confirm-modal"
                title={modalConfig.title}
                content={modalConfig.content}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </div>
    );
};

export default ShipmentPage;
