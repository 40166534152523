import React, { useEffect } from 'react';
import M from 'materialize-css';

const Fab = () => {
    useEffect(() => {
        // Initialize Materialize CSS Floating Action Button
        const elems = document.querySelectorAll('.fixed-action-btn');
        M.FloatingActionButton.init(elems,{
            direction: 'left'
          });
    }, []);

    return (
        <div className="fixed-action-btn">
            <a data-target="mobile-demo" className="sidenav-trigger">
                        <i className="material-icons">menu</i>
                    </a>
        </div>
    );
};

export default Fab;
