import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SalesPage.css';
import DatePicker from '../../utilities/DatePicker';
import MoneyInput from '../../utilities/MoneyInput';
import FormCheckbox from '../../utilities/FormCheckbox';
import NumberInput from '../../utilities/NumberInput';
import TextAreaField from '../../utilities/TextAreaField';
import TextInput from '../../utilities/TextInput';
import ItemSelector from '../ItemSelector/ItemSelector';
import IconHyperlink from '../../utilities/IconHyperlink';
import ModalBox from '../../utilities/ModalBox'; // Import the ModalBox component
import M from 'materialize-css'; // Import MaterializeCSS
import moment from 'moment';
import MaterializeCardWithCarousel from '../../components/MaterializeCardWithCarousel/MaterializeCardWithCarousel'; // Import the new component
import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants';


const SalesPage = () => {
    const [sales, setSales] = useState([]);
    const [items, setItems] = useState([]);
    const [itemSalesQuantities, setItemSalesQuantities] = useState({});
    const [form, setForm] = useState({
        items: {},
        name: '',
        salesDate: moment().format('DD/MM/YYYY'), // Set to current date initially
        price: '',
        buyerDetails: '',
        phoneNumber: '',
        salesStatus: 'SP',  // Default status set to "SP"
        systemDate: '', // Adding system date
        giveAway: false // Adding give away checkbox state
    });
    const [originalPrice, setOriginalPrice] = useState(''); // To store the original price
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [selectedSale, setSelectedSale] = useState(null); // State for selected sale
    const [modalConfig, setModalConfig] = useState({ visible: false, action: null, title: '', content: '', id: null });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchSales(); // Fetch sales data
                const itemsResponse = await axios.get(`${API_BASE_URL}/items`);
                setItems(itemsResponse.data || []);
                const elems = document.querySelectorAll('.modal');
                M.Modal.init(elems);
            } catch (error) {
                console.error('Failed to fetch data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (isEditing) {
            calculateTotalPrice(form.items);
        } else {
            calculateTotalPrice();
        }
    }, [form.items, isEditing]);

    useEffect(() => {
        if (items.length > 0) {
            fetchItemSalesQuantity();
        }
    }, [items]);

    useEffect(() => {
        if (selectedSale) {
            const elem = document.getElementById('view-modal');
            if (elem) {
                const instance = M.Modal.init(elem);
                instance.open();
            }
        }
    }, [selectedSale]);

    const fetchSales = async () => {
        try {
            const salesResponse = await axios.get(`${API_BASE_URL}/salespending`);
            setSales(salesResponse.data || []);
        } catch (error) {
            console.error('Failed to fetch sales data', error);
        }
    };

    const fetchItemSalesQuantity = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/sales`);
            const salesData = response.data || [];
            const itemQuantities = {};

            salesData.forEach(sale => {
                Object.keys(sale.items).forEach(inventoryid => {
                    if (!itemQuantities[inventoryid]) {
                        itemQuantities[inventoryid] = 0;
                    }
                    itemQuantities[inventoryid] += sale.items[inventoryid][0]; // Assuming the quantity is the first element
                });
            });

            setItemSalesQuantities(itemQuantities);
        } catch (error) {
            console.error('Failed to fetch sales data', error);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!form.name) newErrors.name = 'Name is required';
        if (!form.salesDate) newErrors.salesDate = 'Date is required';
        if (!form.price && !form.giveAway) newErrors.price = 'Price is required';
        if (!form.buyerDetails) newErrors.buyerDetails = 'Address is required';
        if (!form.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        if (Object.keys(form.items).length === 0) newErrors.items = 'At least one item must be selected';
        return newErrors;
    };

    const handleSelectItem = (item) => {
        const newItems = { ...form.items };
        if (newItems[item.inventoryid]) {
            delete newItems[item.inventoryid];
        } else {
            newItems[item.inventoryid] = [1, item.sellingprice, 0]; // Default values: quantity, selling price, discount
        }
        const newErrors = { ...errors };
        if (Object.keys(newItems).length > 0) {
            delete newErrors.items;
        }
        setForm({ ...form, items: newItems });
        setErrors(newErrors);
        if (isEditing) {
            calculateTotalPrice(newItems);
        }
    };

    const handleFieldChange = (field, value) => {
        const newErrors = { ...errors };
        delete newErrors[field];
        setErrors(newErrors);
        setForm({ ...form, [field]: value });
    };

    const handleItemDetailChange = (inventoryid, index, value) => {
        const newItems = { ...form.items };
        newItems[inventoryid][index] = Number(value);
        setForm({ ...form, items: newItems });
        if (isEditing) {
            calculateTotalPrice(newItems);
        }
    };

    const calculateFinalPrice = (inventoryid) => {
        const itemDetails = form.items[inventoryid] || [1, 0, 0];
        const [quantity, sellingPrice, discount] = itemDetails;
        return (sellingPrice * quantity * (1 - discount / 100)).toFixed(2);
    };

    const calculateTotalPrice = (items = form.items) => {
        const totalPrice = Object.keys(items).reduce((total, inventoryid) => {
            return total + parseFloat(calculateFinalPrice(inventoryid));
        }, 0).toFixed(2);
        setForm(prevForm => ({ ...prevForm, price: totalPrice }));
        if (!form.giveAway) {
            setOriginalPrice(totalPrice); // Save the original price if not a giveaway
        }
    };

    const handleAdd = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setModalConfig({
            visible: true,
            action: 'add',
            title: 'Confirm Add',
            content: 'Are you sure you want to add this sale?',
            id: null
        });
        openModal();
    };

    const handleEdit = (id) => {
        const saleToEdit = sales.find(sale => sale.id === id);
        setForm({
            ...saleToEdit,
            salesDate: moment(saleToEdit.salesDate).format('DD/MM/YYYY'), // Format date for editing
            systemDate: moment().format('YYYY-MM-DD'),
            items: saleToEdit.items || {},
            price: saleToEdit.price // Ensure price is set from the database
        });
        setIsEditing(true);
        setEditId(id);
        window.scrollTo(0, 0); // Scroll to top when editing
    };

    const handleGiveAwayChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setOriginalPrice(form.price); // Save the original price
            setForm({
                ...form,
                giveAway: true,
                price: '0' // Set price to 0 when giveaway is checked
            });
        } else {
            setForm({
                ...form,
                giveAway: false,
                price: originalPrice // Restore the original price when giveaway is unchecked
            });
        }
    };

    const handleUpdate = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setModalConfig({
            visible: true,
            action: 'update',
            title: 'Confirm Update',
            content: 'Are you sure you want to update this sale?',
            id: editId
        });
        openModal();
    };

    const handleDelete = (id) => {
        setModalConfig({
            visible: true,
            action: 'delete',
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete this sale?',
            id
        });
        openModal();
    };

    const openModal = () => {
        const elem = document.getElementById('confirm-modal');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleConfirm = () => {
        if (modalConfig.action === 'add') {
            const systemDate = moment().format('YYYY-MM-DD');
            const salesDate = moment(form.salesDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            const shipmentDate = form.shipmentDate ? moment(form.shipmentDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
            const newSale = { ...form, salesDate, systemDate, shipmentDate, salesStatus: 'SP' };
            
            axios.post(`${API_BASE_URL}/sales`, newSale).then(() => {
                fetchSales(); // Refetch sales to update the list
                resetForm();
            }).catch(error => {
                console.error('Error adding sale:', error);
            });
        } else if (modalConfig.action === 'update') {
            const salesDate = moment(form.salesDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
         //   const shipmentDate = form.shipmentDate ? moment(form.shipmentDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
            const updatedSale = { ...form, salesDate, id: editId, salesStatus: 'SP' };

            axios.put(`${API_BASE_URL}/sales/${editId}`, updatedSale).then(() => {
                fetchSales(); // Refetch sales to update the list
                resetForm();
            }).catch(error => {
                console.error('Error updating sale:', error);
            });
        } else if (modalConfig.action === 'delete') {
            axios.delete(`${API_BASE_URL}/sales/${modalConfig.id}`).then(() => {
                fetchSales(); // Refetch sales to update the list
                resetForm(); // Reset form to add mode after deletion
            }).catch(error => {
                console.error('Error deleting sale:', error);
            });
        }
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const handleCancel = () => {
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const resetForm = () => {
        setForm({
            items: {},
            name: '',
            salesDate: moment().format('DD/MM/YYYY'), // Reset to current date
            price: '',
            buyerDetails: '',
            phoneNumber: '',
            salesStatus: 'SP',
            systemDate: '',
            giveAway: false
        });
        setIsEditing(false); // Reset editing state
        setEditId(null); // Reset editId
        setOriginalPrice(''); // Reset original price
        setErrors({});
    };

    const handleRemoveItem = (inventoryid) => {
        const newItems = { ...form.items };
        delete newItems[inventoryid];
        setForm({ ...form, items: newItems });

        const newErrors = { ...errors };
        if (Object.keys(newItems).length === 0) {
            newErrors.items = 'At least one item must be selected';
        }
        setErrors(newErrors);
        if (isEditing) {
            calculateTotalPrice(newItems);
        }
    };

    const handleViewClick = (e, sale) => {
        e.preventDefault(); 
        setSelectedSale(sale);
    };

    const handleCloseModal = () => {
        const elem = document.getElementById('view-modal');
        if (elem) {
            const instance = M.Modal.getInstance(elem);
            if (instance) {
                instance.close();
            }
        }
        setSelectedSale(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="sales-form">
                <ItemSelector 
                    items={items} 
                    selectedItems={Object.keys(form.items)} 
                    onSelectItem={handleSelectItem} 
                />
                <form className="form-grid">
                    {errors.items && <span className="error-message red-text">{errors.items}</span>}
                    <div>
                        <div>
                            {Object.keys(form.items).map(inventoryid => {
                                const selectedItem = items.find(item => item.inventoryid == inventoryid);
                                if (!selectedItem) {
                                    return null; // Safeguard to ensure selectedItem is defined
                                }
                                const originalInventory = parseInt(selectedItem.quantity, 10);
                                const reducedInventory = itemSalesQuantities[inventoryid] || 0;
                                const balance = originalInventory - reducedInventory;
                                const [quantity, sellingPrice, discount] = form.items[inventoryid];
                                return (
                                    <div key={inventoryid} className="row card lime lighten-4">
                                        <div className="col s3 l1">
                                            <img src={IMAGE_BASE_URL+selectedItem.image} alt="Item" className="selected-item-thumbnail" />
                                        </div>
                                        <div className="col s5 l1">
                                            {selectedItem.code}<br></br>
                                            Box: {selectedItem.boxno}<br></br>
                                                Balance: {balance}<br></br>
                                              Org Price: {selectedItem.price}{"  ₹"}
                                               
                                        </div>
                        
                             
                                        <div className="col s4 l2" >
                                        <NumberInput
                                                icon="%"
                                                name={`discount-${inventoryid}`}
                                                value={discount}
                                                onChange={(e) => handleItemDetailChange(inventoryid, 2, e.target.value)}
                                            />
                                       
                                        </div>
                                        <div className="col s6 l2" >
                    
                                        <MoneyInput
                                                icon="₹"
                                                name={`sellingPrice-${inventoryid}`}
                                                value={sellingPrice}
                                                onChange={(e) => handleItemDetailChange(inventoryid, 1, e.target.value)}
                                            />
                                        </div>
                                        <div className="col s6 l2" >
                                        <NumberInput
                                                icon="#"
                                                name={`quantity-${inventoryid}`}
                                                value={quantity}
                                                onChange={(e) => handleItemDetailChange(inventoryid, 0, e.target.value)}
                                        
                                            />
                                        </div>
                                        <div className="col s6 l1">
                                        Final Price: {calculateFinalPrice(inventoryid)}
                                        </div>
                                        <div className="col s6 l3 right-align">
                                                <IconHyperlink
                                                href="#"
                                                onClick={() => handleRemoveItem(inventoryid)}
                                                icon="close"
                                                iconSize="small"
                                                additionalClasses="black-text"
                                            />
                                        </div>
                        
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="row">
                    <div className="col s6">
                        <TextInput
                            label="Name"
                            name="name"
                            value={form.name}
                            onChange={(e) => handleFieldChange('name', e.target.value)}
                        />
                        {errors.name && <span className="error-message red-text">{errors.name}</span>}
                    </div>
                    <div className="col s6">

                                  <TextInput
                                  icon="local_phone"
                            name="phoneNumber"
                            value={form.phoneNumber}
                            onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
                        />
                        {errors.phoneNumber && <span className="error-message red-text">{errors.phoneNumber}</span>}
                    </div>
                    <div className="col s6">
                        <DatePicker
                            label="Date"
                            name="salesDate"
                            value={form.salesDate}
                            onChange={(e) => handleFieldChange('salesDate', e.target.value)}
                        />
                        {errors.salesDate && <span className="error-message red-text">{errors.salesDate}</span>}
                    </div>
                    <div className="col s6">
                        <MoneyInput
                            icon="₹"
                            name="price"
                            value={form.price}
                            onChange={(e) => handleFieldChange('price', e.target.value)} 
                        />
                        {errors.price && <span className="error-message red-text">{errors.price}</span>}
                    </div>
                    <div className="col s12">
                        <TextAreaField
                            id="buyerDetails"
                            value={form.buyerDetails}
                            onChange={(e) => handleFieldChange('buyerDetails', e.target.value)}
                            label="Address"
                            maxLength={120}
                        />
                        {errors.buyerDetails && <span className="error-message red-text">{errors.buyerDetails}</span>}
                    </div>
                    <div className="col s12">
                        <FormCheckbox
                            name="giveAway"
                            checked={form.giveAway}
                            onChange={handleGiveAwayChange}
                            label="Give Away"
                        />
                    </div>
                    <div className="input-field col s12 center-align">
                        {isEditing ? (
                            <>
                                <button type="button" onClick={handleUpdate} className="btn btn-warning">Update Sale</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" onClick={resetForm} className="btn btn-secondary">Cancel</button>
                            </>
                        ) : (
                            <button type="button" onClick={handleAdd} className="btn btn-primary">Add Sale</button>
                        )}
                    </div>
                    </div>
                </form>
            </div>

            <div className="sales-list row">
                <h4>Pre Shipment List</h4>
                <ul className="list-group">
                    {sales.map((sale, index) => (
                        <div className="col s12 m4 l3" key={sale.id}>
                            <div className="card horizontal">
                                <div className="card-stacked">
                                    <div className="card-content">
                                    <p><strong>{index + 1}. {sale.name}</strong> :&nbsp;&nbsp;{sale.price}₹</p>
                                    </div>
                                    <div className="card-action">
                                    <a href="#" onClick={(e) => handleViewClick(e, sale)}>View</a>
                                        <a href="#" onClick={() => handleEdit(sale.id)} className="secondary-content">
                                            <i className="material-icons">edit</i>
                                        </a>
                                        <a href="#" onClick={() => handleDelete(sale.id)} className="secondary-content red-text">
                                            <i className="material-icons">delete</i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </ul>
            </div>

            {selectedSale && (
                <div id="view-modal" className="modal">
                    <div className="modal-content">
                        <h4>{selectedSale.name}</h4>
                        <MaterializeCardWithCarousel
                            items={selectedSale.items}
                            itemsList={items}
                            name={selectedSale.name}
                            buyerdetails={selectedSale.buyerDetails}
                            totalprice={selectedSale.price}
                            phonenumber={selectedSale.phoneNumber}
                        />
                    </div>
                    <div className="modal-footer">
                        <button className="modal-close waves-effect waves-green btn-flat" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            )}

            <ModalBox
                id="confirm-modal"
                title={modalConfig.title}
                content={modalConfig.content}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </div>
    );
};

export default SalesPage;
