import React, { useState } from 'react';
import './MaterializeCardWithCarousel.css'; // Create a CSS file for styling if needed
import IconHyperlink from './../../utilities/IconHyperlink';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants';
const MaterializeCardWithCarousel = ({ items, itemsList, name, buyerdetails, totalprice, phonenumber, editClick, deleteClick, deleteIcon, editIcon, shipmentPrice, shipmentDate }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [detailsVisible, setDetailsVisible] = useState(false);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(price);
  };

  const getItemDetails = () => {
    return Object.keys(items).map(itemId => {
      const item = itemsList.find(item => item.inventoryid === itemId);
      return item ? { ...item, quantity: items[itemId][0], price: items[itemId][1], discount: items[itemId][2] } : null;
    }).filter(item => item !== null);
  };

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleImageClick = () => {
    setDetailsVisible(!detailsVisible);
  };

  const itemDetails = getItemDetails();
  const totalPages = itemDetails.length;

  return (
    <div className="card small-card">
      <div className="row">
        <div className="col s8">
          <strong>{name}</strong>
        </div>
        <div className="col s2">
          <IconHyperlink
            href="#"
            onClick={editClick}
            icon={editIcon}
            iconSize="small"
            additionalClasses=""
          />
        </div>
        <div className="col s2">
          <IconHyperlink
            href="#"
            onClick={deleteClick}
            icon={deleteIcon}
            iconSize="small"
            additionalClasses=""
          />
        </div>
        <div className="col s12">
          {itemDetails.length > 0 && (
            <div onClick={handleImageClick}>
              <img
                src={IMAGE_BASE_URL+itemDetails[currentPage].image}
                alt="Item"
                className="carousel-image"
              />
              {detailsVisible && (
                <div className="item-details">
                  <p>Code: {itemDetails[currentPage].code}</p>
                  <p>Box #: {itemDetails[currentPage].boxno}</p>
                  <p>Quantity: {itemDetails[currentPage].quantity}</p>
                  <p>Price: {formatPrice(itemDetails[currentPage].price)}</p>
                  <p>Discount: {itemDetails[currentPage].discount}%</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col s12 pagination-container">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`pagination-button ${index === currentPage ? 'active' : ''}`}
              onClick={() => handlePageClick(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="col s12">
          <ul className="collapsible">
            <li>
              <div className="collapsible-header">
                Buyer Details
                <span className="new badge" data-badge-caption=" ">{buyerdetails}</span>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                Total Price
                <span className="new badge" data-badge-caption=" ">{formatPrice(totalprice)}</span>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                Phone Number
                <span className="new badge" data-badge-caption=" ">{phonenumber}</span>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                Shipment Price
                <span className="new badge" data-badge-caption=" ">{shipmentPrice}</span>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                Shipment Date
                <span className="new badge" data-badge-caption=" ">{shipmentDate}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MaterializeCardWithCarousel;
