import React, { useEffect } from 'react';
import M from 'materialize-css';
import Sidenav from './../SideNav/Sidenav';

const MenuBar = () => {
    useEffect(() => {
        // Initialize Materialize CSS components
        const elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }, []);



    return (
        <>
            <nav className="fixed-nav-bar">
                <div className="nav-wrapper blue">
                    <a href="#!" className="brand-logo">Illolam Jewels</a>
                   
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                        <i className="material-icons">menu</i>
                    </a>
                    <ul className="right hide-on-med-and-down">
                        <li><a href="/invetory">Add Inventory</a></li>
                        <li><a href="/sales">Sales</a></li>
                        <li><a href="/shipment">Shipment</a></li>
                        <li><a href="/inventory-details">Search Inventory</a></li>
                        <li><a href="/analytics">Reports</a></li>
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
            <li><a href="/invetory">Add Inventory</a></li>
                        <li><a href="/sales">Sales</a></li>
                        <li><a href="/shipment">Shipment</a></li>
                        <li><a href="/inventory-details">Search Inventory</a></li>
                        <li><a href="/analytics">Reports</a></li>
            </ul>
        </>
    );
};

export default MenuBar;
