// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.small-card {
    padding: 20px;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
    color: white;
  }
  .carousel-image {
    width: 190px;
    height: 190px;
    cursor: pointer;
  }
  
  .item-details {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    margin-top: 10px;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .pagination-button {
    border: none;
    background-color: #e0e0e0;
    color: #000;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination-button.active {
    background-color: #007bff;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MaterializeCardWithCarousel/MaterializeCardWithCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,YAAY;IACZ,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":[".card.small-card {\n    padding: 20px;\n  }\n  \n  .carousel-item img {\n    width: 100%;\n    height: auto;\n  }\n  \n  .carousel-caption {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 5px;\n    border-radius: 5px;\n    color: white;\n  }\n  .carousel-image {\n    width: 190px;\n    height: 190px;\n    cursor: pointer;\n  }\n  \n  .item-details {\n    padding: 10px;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    margin-top: 10px;\n  }\n  \n  .pagination-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n  }\n  \n  .pagination-button {\n    border: none;\n    background-color: #e0e0e0;\n    color: #000;\n    padding: 5px 10px;\n    margin: 0 5px;\n    cursor: pointer;\n  }\n  \n  .pagination-button.active {\n    background-color: #007bff;\n    color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
