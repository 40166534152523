import React, { useEffect } from 'react';
import M from 'materialize-css';
import InventoryDetails from './../InventoryDetailsPage/InventoryDetails';
const Sidenav = () => {
    useEffect(() => {
        // Initialize Materialize CSS Sidenav
        const elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }, []);


    return (
        <>

            <div  id="slide-out" className="sidenav"><InventoryDetails/></div>
           
            <a class="sidenav-trigger btn-floating pulse blue" data-target="slide-out" ><i class="material-icons">menu</i></a>
        </>
    );
};

export default Sidenav;
