// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .card-image-fixed {
    max-width:155px;
    height:165px
  }
  
.card .row{
  margin-bottom:0px;
  padding :5px;
}
  .leftspace{
    padding-left:20px
  }`, "",{"version":3,"sources":["webpack://./src/components/ItemCard/MaterializeCard.css"],"names":[],"mappings":";EACE;IACE,eAAe;IACf;EACF;;AAEF;EACE,iBAAiB;EACjB,YAAY;AACd;EACE;IACE;EACF","sourcesContent":["\n  .card-image-fixed {\n    max-width:155px;\n    height:165px\n  }\n  \n.card .row{\n  margin-bottom:0px;\n  padding :5px;\n}\n  .leftspace{\n    padding-left:20px\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
