import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InventoryPage from './components/InventoryPage/Inventory';
import SalesPage from './components/SalesRecordPage/SalesRecord';
import NavBar from './components/NavigationBar/NavigationBar';
import ShipmentPage from './components/ShipmentPage/shipment';
import AnalyticsPage from './components/AnalyticsPage/Analytics';
import InventoryDetails from './components/InventoryDetailsPage/InventoryDetails';
import MenuBar from './components/MenuBar/MenuBar'; // Adjust the path as needed
import Fab from './components/FloatingActionButton/Fab';
import './index.css';
import LandingPage from './components/LandingPage/LandingPage';
import PrivateRoute from './PrivateRoute'; 
function App() {

    return (
        <Router>
            <div class="container">
            <MenuBar />
            
           
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/invetory" element={<PrivateRoute><InventoryPage /></PrivateRoute>} />
                <Route path="/sales"  element={<PrivateRoute><SalesPage /></PrivateRoute>}/>
                <Route path="/shipment"  element={<PrivateRoute><ShipmentPage /></PrivateRoute>} />
                <Route path="/analytics" element={<PrivateRoute><AnalyticsPage /></PrivateRoute>}/>
                <Route path="/inventory-details" element={<PrivateRoute><InventoryDetails /></PrivateRoute>}/>
            </Routes>
            </div>
        </Router>
    );
}

export default App;
