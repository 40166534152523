// src/LandingPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const LandingPage = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        navigate('/sales'); 
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Handle Google Sign-In
  const handleGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        console.log("User signed in:", result.user);
      })
      .catch((error) => {
        console.error("Error during Google Sign-In:", error);
      });
  };

  // Handle Sign-Out
  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        console.log("User signed out");
      })
      .catch((error) => {
        console.error("Error during Sign-Out:", error);
      });
  };
  return (
    <div className="App">
    <h1>Sign in using your Google Account</h1>
    {user ? (
      <div>
        <p>Welcome, {user.displayName}</p>
        <img src={user.photoURL} alt="User Avatar" style={{ width: '50px', borderRadius: '50%' }} />
        <br />
        <button onClick={handleSignOut}>Sign out</button>
      </div>
    ) : (
      <button onClick={handleGoogleSignIn}>Sign in with Google</button>
    )}
  </div>
);
};

export default LandingPage;
