import React, { useEffect, useRef } from 'react';
import M from 'materialize-css';
import moment from 'moment';

const DatePicker = ({ label, name, value, onChange, placeholder }) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const currentDate = moment().format('DD/MM/YYYY');
    const options = {
      format: 'dd/mm/yyyy',
      autoClose: true,
      setDefaultDate: !!value,
      defaultDate: value ? new Date(value.split('/').reverse().join('-') + 'T12:00:00') : new Date(currentDate.split('/').reverse().join('-') + 'T12:00:00'),
      onSelect: (date) => {
        const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
        const event = {
          target: {
            name: name,
            value: formattedDate,
          },
        };
        onChange(event);
      },
    };

    const instance = M.Datepicker.init(datePickerRef.current, options);

    if (value) {
      instance.el.value = value;
    } else {
      instance.el.value = currentDate;
    }

    return () => {
      instance.destroy();
    };
  }, [name, onChange, value]);

  return (
    <div className="input-field col s12">
      <input
        type="text"
        name={name}
        ref={datePickerRef}
        className="datepicker"
        placeholder={placeholder}
        defaultValue={value || moment().format('DD/MM/YYYY')}
      />
      <label htmlFor={name} className={value || moment().format('DD/MM/YYYY') ? 'active' : ''}>{label}</label>
    </div>
  );
};

export default DatePicker;
