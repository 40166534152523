import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import MaterializeCardWithCarousel from '../../components/MaterializeCardWithCarousel/MaterializeCardWithCarousel'; // Import the new component

import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants';

const AnalyticsPage = () => {
    const [sales, setSales] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [profitStartDate, setProfitStartDate] = useState('');
    const [profitEndDate, setProfitEndDate] = useState('');
    const [filteredProfitSales, setFilteredProfitSales] = useState([]);

    let sellingPrice = 0;
    let buyingPrice = 0;
    let totalQuantity = 0;
    let profit = 0;
    let shipmentTotal = 0;
    let boxCountPrice = 0;
    let profitAmount = 0;

    useEffect(() => {
        axios.get(`${API_BASE_URL}/sales`).then(response => {
            setSales(response.data);
            setDefaultDateRanges();
        }).catch(error => {
            console.error('Error fetching sales data:', error);
        });

        axios.get(`${API_BASE_URL}/items`).then(response => {
            setInventory(response.data);
        }).catch(error => {
            console.error('Error fetching inventory data:', error);
        });
    }, []);

    const setDefaultDateRanges = () => {
        const today = new Date();
        const last30Days = new Date(today.setDate(today.getDate() - 30)).toISOString().split('T')[0];
        const todayString = new Date().toISOString().split('T')[0];

        setProfitStartDate(last30Days);
        setProfitEndDate(todayString);
    };

    useEffect(() => {
        if (profitStartDate && profitEndDate) {
            const filtered = sales.filter(sale => {
                const saleDate = moment(sale.sales_date, 'YYYY-MM-DD'); // Uniform date format
                const startDate = moment(profitStartDate, 'YYYY-MM-DD');
                const endDate = moment(profitEndDate, 'YYYY-MM-DD');

                return saleDate.isBetween(startDate, endDate, null, '[]'); // Inclusive of both start and end dates
            });
            setFilteredProfitSales(filtered);
        }
    }, [profitStartDate, profitEndDate, sales]);

    const calculateProfitData = () => {
        sellingPrice = 0;
        buyingPrice = 0;
        totalQuantity = 0;
        profit = 0;
        shipmentTotal = 0;
        boxCountPrice = 0;
        profitAmount = 0;

        filteredProfitSales.forEach(sale => {
            Object.keys(sale.items).forEach(itemId => {
                const item = inventory.find(i => i.inventoryid === itemId);
                if (item) {
                    const quantitySold = parseInt(sale.items[itemId][0]);
                    const sellingPricePerItem = parseInt(sale.items[itemId][1]);

                    totalQuantity += quantitySold;
                    sellingPrice += sellingPricePerItem * quantitySold;
                    buyingPrice += item.price * quantitySold;
                    shipmentTotal += sale.shipment_price ? parseInt(sale.shipment_price) : 0;
                }
            });

            boxCountPrice += 20; // Assuming a fixed box price
        });
        profitAmount = (sellingPrice - buyingPrice - shipmentTotal - boxCountPrice);
        profit = ((profitAmount / buyingPrice) * 100).toFixed(2);
    };

    calculateProfitData();

    const getProfitData = () => {
return (
            <div className="sales-list row">
            <h4>Sales List</h4>
            <ul className="list-group">
            {filteredProfitSales.map(sale => (
                    <div className="col s12 m4 l3" key={sale.id}>
                        <MaterializeCardWithCarousel
                            items={sale.items}
                            itemsList={inventory} // Passing the items list as a prop
                            name={sale.name} // Passing the name prop
                            buyerdetails={sale.buyer_details}
                            totalprice={sale.price}
                            phonenumber={sale.phone_number}
    
                            shipmentPrice={sale.shipment_price}
                            shipmentDate={moment(sale.shipment_date).format('DD/MM/YYYY')}
                        />
                    </div>
                ))}
            </ul>
        </div>
);
    };
    

    return (
        <div>
            <h2>Profit</h2>
            <div>
                <label>Start Date:</label>
                <input type="date" value={profitStartDate} onChange={(e) => setProfitStartDate(e.target.value)} />
                <label>End Date:</label>
                <input type="date" value={profitEndDate} onChange={(e) => setProfitEndDate(e.target.value)} />
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Buying Price</th>
                        <th>Selling Price</th>
                        <th>Shipment</th>
                        <th>Packaging</th>
                        <th>Quantity</th>
                        <th>Profit</th>
                        <th>Profit Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{buyingPrice}</td>
                        <td>{sellingPrice}</td>
                        <td>{shipmentTotal}</td>
                        <td>{boxCountPrice}</td>
                        <td>{totalQuantity}</td>
                        <td>{profitAmount}</td>
                        <td>{profit}</td>
                    </tr>
                </tbody>
            </table>
            {getProfitData()}
        </div>
    );
};

export default AnalyticsPage;
