import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconHyperlink from './../../utilities/IconHyperlink'; // Adjust the path as needed
import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants';
const ImageSelection = ({  handleImageSelect, selectedImage }) => {

    const [currentPath, setCurrentPath] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const imageResponse = await axios.get(`${IMAGE_BASE_URL}/api/images`);
            setCurrentImages(imageResponse.data);
        } catch (error) {
            console.error('Failed to fetch data', error);
        }
    };
    
    const renderImageTree = () => {
        return currentImages.map((node, index) => (
       
                <div key={index} className="col s8 m12 l2">
                    <img
                        src={`${IMAGE_BASE_URL}${node.path}`}
                        alt={node.name}
                        className={selectedImage === node.path ? 'selected-image' : ''}
                        style={{ width: '125px', height: '125px', cursor: 'pointer' }}
                        onClick={() => handleImageSelect(node.path)}
                    />
                    <div>{node.name.split('/').pop().split('.').shift()}</div>
                </div>
        
        ));
    };

    return (
        <div className="row">
            <div className="col s12">
                <div className="image-section">
                    <div className="horizontal-scroll">
                        {renderImageTree()}
                    </div>
                    {currentPath.length > 0 && (
                        <div className="mt-2 text-center blue-text">
                            <strong>{currentPath[currentPath.length - 1]}</strong>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageSelection;
