import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SelectBox from './../../utilities/SelectBox';
import MoneyInput from './../../utilities/MoneyInput';
import NumberInput from './../../utilities/NumberInput';
import InputButton from './../../utilities/InputButton';
import MaterializeCard from './../ItemCard/MaterializeCard';
import FormCheckbox from './../../utilities/FormCheckbox';
import DatePicker from './../../utilities/DatePicker';
import ModalBox from './../../utilities/ModalBox';
//import M from 'materialize-css';
//import './inventory.css';
import ImageSelection from './../ImageSelection/ImageSelection';
import TextInput from './../../utilities/TextInput';
import { API_BASE_URL, IMAGE_BASE_URL, APPIMAGE_BASE_URL } from '../../config/constants';




const itemCategory = [
       { value: '1', label: 'Necklace' },
       { value: '2', label: 'Haarams' },
       { value: '3', label: 'AD Stone Jewellery' },
       { value: '4', label: 'Bangles & Bracelets' },
       { value: '5', label: 'Maala' }
  ];

  const subcategoryOptionsDefault = [
    { value: '0', label: 'None' },
    { value: '1', label: 'Red' },
    { value: '2', label: 'Green' },
    { value: '3', label: 'White' },
    { value: '4', label: 'Mixed' },
  ];
  
  const subcategoryOptionsBangles = [
    { value: '0', label: 'None' },
    { value: '1', label: '2.2' },
    { value: '2', label: '2.4' },
    { value: '3', label: '2.6' },
    { value: '4', label: '2.8' },
  ];
  

const InventoryPage = () => {
    const [items, setItems] = useState([]);
    const [imagesTree, setImagesTree] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);
    const [parentOptions, setParentOptions] = useState([]); 
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [subcategoryOptions, setSubcategoryOptions] = useState(subcategoryOptionsDefault); 

    const [form, setForm] = useState({
        code: '',
        category: '1',
        sellingprice: '',
        subcategory:'-1',
        price: '',
        quantity: '',
        parent: '1',
        image: '',
        publish: false,
        publishedurl: '',
        boxno: '',
        purchaseDate: new Date().toISOString().split('T')[0],
        label: '' // New field for Label
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [modalConfig, setModalConfig] = useState({ visible: false, action: null, title: '', content: '', id: null });
    const [imageModalConfig, setImageModalConfig] = useState({ visible: false, imagePath: '' });
    const [showImageSelection, setShowImageSelection] = useState(false);
    const [priceAdjustmentModal, setPriceAdjustmentModal] = useState({
        visible: false,
        price: 0,
        adjustedPrice: 0,
        calculations: [],
        checkboxChecked: false,
    });

    useEffect(() => {
        fetchData();
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    }, []);

    const fetchData = async () => {
        try {
          const itemResponse = await axios.get(`${API_BASE_URL}/items`);
          setItems(itemResponse.data);
    
          // Set parent options for the "Link Parent" select box
          const parentItems = itemResponse.data.map((item) => ({ value: item.inventoryid, label: item.label }));
          setParentOptions([{ value: '1', label: 'Is Parent' }, ...parentItems]);
    
          const imageResponse = await axios.get(`${IMAGE_BASE_URL}/api/images`);
          setImagesTree(imageResponse.data);
          setCurrentImages(imageResponse.data);
        } catch (error) {
          console.error('Failed to fetch data', error);
        }
      };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        if (name === 'category') {
          // Update subcategory options based on selected category
          if (value === '4') {
            setSubcategoryOptions(subcategoryOptionsBangles);
          } 
        else {
            setSubcategoryOptions(subcategoryOptionsDefault);
          }
      
          // Reset subcategory when category changes
          setForm({ ...form, [name]: value, subcategory: '0' });
        } 
  else {
          setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
        }
      };
      
      

    const uploadImage = async () => {
        if (!file) {
            setUploadStatus('No file selected');
            return null;
        }

        try {
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post(`${IMAGE_BASE_URL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setUploadStatus(`File uploaded successfully: ${response.data.filePath}`);

            return response.data.filePath;
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Error uploading file');
            return null;
        }
    };

    const handleAdd = async () => {
        if (!form.price || !form.sellingprice || !form.quantity || !form.boxno || !file  || !form.label) {
            M.toast({ html: 'Please fill in all required fields and upload an image.' });
            return;
        }
    
        let imagePath = form.image;
        let code = form.code;
    
        if (!imagePath && file) {
            const uploadedFilePath = await uploadImage();
    
            if (!uploadedFilePath) {
                M.toast({ html: 'Image upload failed. Cannot add item.' });
                return;
            }
    
            imagePath = '/images/' + uploadedFilePath;
            code = uploadedFilePath.split('.').shift();
        }
    
        const newItem = {
            ...form,
            inventoryid: Date.now(),
            systemdate: new Date().toISOString(),
            price: parseFloat(form.price),
            sellingprice: parseFloat(form.sellingprice),
            quantity: parseInt(form.quantity, 10),
            boxno: parseInt(form.boxno, 10),
            code: code,
            image: imagePath,
            label: form.label // Add the new label field
        };
    
        setModalConfig({
            visible: true,
            action: 'add',
            title: 'Confirm Add',
            content: 'Are you sure you want to add this item?',
            item: newItem
        });
        openModal();
    };
    
    const handleUpdate = async () => {
        if (!form.price || !form.sellingprice || !form.quantity || !form.boxno || (!file && !form.image || !form.label)) {
            M.toast({ html: 'Please fill in all required fields and upload an image.' });
            return;
        }
    
        let imagePath = form.image;
        let code = form.code;
    
        // Check if a new file has been selected for upload
        if (file) {
            const uploadedFilePath = await uploadImage();
    
            if (!uploadedFilePath) {
                M.toast({ html: 'Image upload failed. Cannot update item.' });
                return;
            }
    
            imagePath = '/images/' + uploadedFilePath; // Use the newly uploaded image
            code = uploadedFilePath.split('.').shift();
        }
    
        const updatedItem = {
            ...form,
            price: parseFloat(form.price),
            sellingprice: parseFloat(form.sellingprice),
            quantity: parseInt(form.quantity, 10),
            boxno: parseInt(form.boxno, 10),
            code: code,
            image: imagePath, // Use either the existing or newly uploaded image,
            label: form.label // Add the new label field
        };
    
        try {
            await axios.put(`${API_BASE_URL}/items/${editId}`, updatedItem);
            M.toast({ html: `Item updated successfully. The code is ${form.code}` });
            fetchData();
            resetForm();
        } catch (error) {
            console.error('Failed to update item:', error);
            M.toast({ html: 'Failed to update item. Please try again.' });
        }
    };
    

    const handleEdit = (id) => {
        const item = items.find(item => item.inventoryid === id);
        if (item) {
          const subcategoryOptionsToSet = item.category === '4' ? subcategoryOptionsBangles : subcategoryOptionsDefault;
      
          // Set the subcategory options based on the category
          setSubcategoryOptions(subcategoryOptionsToSet);
      
          setForm({
            code: item.code,
            category: item.category,
            subcategory: item.subcategory || '', // Prepopulate subcategory
            sellingprice: item.sellingprice && item.sellingprice.toString(),
            price: item.price.toString(),
            quantity: item.quantity.toString(),
            image: item.image,
            publish: item.publish,
            publishedurl: item.publishedurl || '',
            boxno: item.boxno.toString(),
            purchaseDate: item.purchaseDate ? item.purchaseDate.split('T')[0] : new Date().toISOString().split('T')[0],
            parent: item.parent || '1',
            label: item.label // Add the new label field
          });
          setIsEditing(true);
          setEditId(id);
        }
      };
      

    const handleDelete = (id) => {
        setModalConfig({
            visible: true,
            action: 'delete',
            title: 'Confirm Delete',
            content: 'Are you sure you want to delete this item?',
            id
        });
        openModal();
    };

    const openModal = () => {
        const elem = document.getElementById('confirm-modal');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleImageSelect = (imagePath) => {
        const imageName = imagePath.split('/').pop().split('.').shift();
        setForm({ ...form, image: `${imagePath}`, code: imageName });
        setFile(null); // Reset the file input to bypass upload
        setShowImageSelection(false); // Hide the ImageSelection component
    };

    const handleImageClick = (imagePath) => {
        setImageModalConfig({
            visible: true,
            imagePath: `http://192.168.29.172:5001${imagePath}`
        });
        openImageModal();
    };

    const openImageModal = () => {
        const elem = document.getElementById('image-modal');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const resetForm = () => {
        setForm({
            code: '',
            category: '1',
            subcategory: '-1',
            sellingprice: '',
            price: '',
            quantity: '',
            image: '',
            publish: false,
            publishedurl: '',
            boxno: '',
            parent: '1',
            purchaseDate: new Date().toISOString().split('T')[0],
            label :''

        });
        setIsEditing(false);
        setEditId(null);
        setSelectedImage('');
        setFile(null);
        setUploadStatus('');
    };

    const toggleImageSelection = () => {
        setShowImageSelection(!showImageSelection);
    };

    const handleConfirm = () => {
        if (modalConfig.action === 'add') {
            axios.post(`${API_BASE_URL}/items`, modalConfig.item).then(() => {
                fetchData();
                M.toast({ html: `New Item inserted and the code is ${form.code}` });
                resetForm();
            }).catch((error) => {
                console.error('Failed to add item:', error);
                M.toast({ html: 'Failed to add item. Please try again.' });
            });
        } else if (modalConfig.action === 'update') {
            axios.put(`${API_BASE_URL}/items/${editId}`, modalConfig.item).then(() => {
                fetchData();
                M.toast({ html: `Item updated successfully. The code is ${form.code}` });
                resetForm();
            }).catch((error) => {
                console.error('Failed to update item:', error);
                M.toast({ html: 'Failed to update item. Please try again.' });
            });
        } else if (modalConfig.action === 'delete') {
            axios.delete(`${API_BASE_URL}/items/${modalConfig.id}`).then(() => {
                fetchData();
                M.toast({ html: 'Item deleted successfully.' });
                resetForm();
            }).catch((error) => {
                console.error('Failed to delete item:', error);
                M.toast({ html: 'Failed to delete item. Please try again.' });
            });
        }
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const handleCancel = () => {
        setModalConfig({ visible: false, action: null, title: '', content: '', id: null });
    };

    const handleImageModalClose = () => {
        setImageModalConfig({ visible: false, imagePath: '' });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const openPriceAdjustmentModal = () => {
        const price = parseFloat(form.price);
        setPriceAdjustmentModal({
            visible: true,
            price: price,
            adjustedPrice: price,
            calculations: [`Starting Price: ${price.toFixed(2)}`],
            checkboxChecked: false,
        });
        const elem = document.getElementById('price-adjustment-modal');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const newPrice = isChecked ? priceAdjustmentModal.price + 320 : priceAdjustmentModal.price;
        const newCalculation = isChecked ? `+ 320: ${newPrice.toFixed(2)}` : `- 320: ${priceAdjustmentModal.price.toFixed(2)}`;
        
        setPriceAdjustmentModal((prevState) => ({
            ...prevState,
            checkboxChecked: isChecked,
            adjustedPrice: newPrice,
            calculations: [...prevState.calculations, newCalculation],
        }));
    };

    const handleAdjustment = (amount) => {
        const newPrice = priceAdjustmentModal.adjustedPrice + amount;
        const newCalculation = `${amount >= 0 ? '+' : ''} ${amount}: ${newPrice.toFixed(2)}`;

        setPriceAdjustmentModal((prevState) => ({
            ...prevState,
            adjustedPrice: newPrice,
            calculations: [...prevState.calculations, newCalculation],
        }));
    };

    const handlePriceAdjustmentConfirm = () => {
        setForm({ ...form, sellingprice: priceAdjustmentModal.adjustedPrice.toFixed(2) });
        closePriceAdjustmentModal();
    };

    const handlePriceAdjustmentCancel = () => {
        closePriceAdjustmentModal();
    };

    const resetPriceAdjustment = () => {
        setPriceAdjustmentModal({
            visible: true,
            price: priceAdjustmentModal.price,
            adjustedPrice: priceAdjustmentModal.price,
            calculations: [`Starting Price: ${priceAdjustmentModal.price.toFixed(2)}`],
            checkboxChecked: false,
        });
    };

    const closePriceAdjustmentModal = () => {
        const elem = document.getElementById('price-adjustment-modal');
        const instance = M.Modal.getInstance(elem);
        instance.close();
        setPriceAdjustmentModal({ visible: false, price: 0, adjustedPrice: 0, calculations: [], checkboxChecked: false });
    };

    return (
        <div>
            <div>

            {false && <div className="marginbottom-zero">
                    <div className="row">
                        <div className="col s5">
                            <div className="file-field input-field">
                                <div className="btn">
                                    <i className="small material-icons blue">image</i>
                                    <input type="file" accept="image/*" onChange={handleFileChange} />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Upload" />
                                </div>
                            </div>
                            
                        </div>
                        <div className="col s2">
                        OR
                        </div>
                        <div className="col s5">
                        <div className="left">Select from</div>
                        <div className="right"><i className="medium material-icons teal-text" style={{ cursor: 'pointer'}} onClick={toggleImageSelection}>folder</i></div>
                        </div>
                    </div>
                    {uploadStatus && <p>{uploadStatus}</p>}
                </div>};
                <div>
                    {showImageSelection && (
                        <div>
                            <ImageSelection handleImageSelect={handleImageSelect} selectedImage={selectedImage} />
                            <i className="material-icons right" style={{ cursor: 'pointer' }} onClick={toggleImageSelection}>close</i>
                        </div>
                    )}
                </div>

                <div className="marginbottom-zero">
                    <form className="row" autoComplete="off">
                        <input type="hidden" name="code" value={form.code} />
                        <div className="col s6 l4">
               
                            <div className="file-field input-field">
                                <div className="btn blue">
                                    <i className="small material-icons">image</i>
                                    <input type="file"  accept="image/*" onChange={handleFileChange} />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Upload" />
                                </div>
          </div>
                            {form.image ? (
                                <img
                                    src={`${IMAGE_BASE_URL}${form.image}`}
                                    alt="Selected"
                                    style={{ width: '100px', height: '100px',marginTop: '25px' }}
                                />
                            ) : file ? (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Selected"
                                    style={{ width: '100px', height: '100px',marginTop: '25px' }}
                                />
                            ):<img
                            src={`${APPIMAGE_BASE_URL}/appimages/selectimage.png`}
                            alt="Selected"
                            style={{ width: '100px', height: '100px',marginTop: '25px' }}
                        />}

                        
                </div>
                <div className="col s6 l4">
                        <TextInput
                  label="Label"
                  name="label"
                  value={form.label}
                  onChange={handleChange}
                        />
                     
                    </div>
                          <div className="col s6 l4">
                            <SelectBox
                                label="Category"
                                name="category"
                                value={form.category}
                                onChange={handleChange}
                                options={itemCategory}
                            />
                        </div>
             
                        <div className="col s6 l4">
                            <SelectBox
                            label="Subcategory"
                            name="subcategory"
                            value={form.subcategory}
                            onChange={handleChange}
                            options={subcategoryOptions} // Dynamic subcategory options
                            />
                        </div>
                        <div className="col s6 l4">
                            <NumberInput
                                label="Quantity"
                                name="quantity"
                                value={form.quantity}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col s6 l4">
                            <MoneyInput
                                label="Price"
                                name="price"
                                value={form.price}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col s6 l4">
                            <NumberInput
                                label="Box No"
                                name="boxno"
                                value={form.boxno}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col s6 l4">
                            <MoneyInput
                                label="Selling Price"
                                name="sellingprice"
                                value={form.sellingprice}
                                onChange={handleChange}
                            />
                            {form.price && (
                                <a href="#!" onClick={openPriceAdjustmentModal} style={{ marginLeft: '10px' }}>
                                    Adjust Price
                                </a>
                            )}
                        </div>

                        <div className="col s6 l4">
                            <DatePicker
                                label="Purchase Date"
                                name="purchaseDate"
                                value={form.purchaseDate}
                                onChange={handleChange}
                                placeholder="Select a date"
                            />
                        </div>
                        <div className="col s6 l4">
            <SelectBox
              label="Link Parent"
              name="parent"
              value={form.parent}
              onChange={handleChange}
              options={parentOptions} // Dynamic options from the fetched items
            />
          </div>

                        <div className="col s6">
                            <FormCheckbox
                                name="publish"
                                checked={form.publish}
                                onChange={handleChange}
                                label="Is Active"
                            />
                        </div>
                        {false && form.publish && (
                            <div className="col s12 l4">
                                <TextInput
                                    label="URL"
                                    name="publishedurl"
                                    id="publishedurl"
                                    value={form.publishedurl}
                                    onChange={handleChange}
                                    className="validate"
                                />
                            </div>
                        )}
                       {!isEditing && <div className="col s12 center-align">
                            <InputButton
                                type="button"
                                onClick={ handleAdd}
                                label={'Add'}
                                icon="add"
                                additionalClasses="btn-small blue"
                            />
                           
                        </div>}
                        {isEditing && (
                            <div className="col s12 center-align">
                                 <InputButton
                                type="button"
                                onClick={handleUpdate}
                                label={'Update' }
                                icon="add"
                                additionalClasses="btn-small blue"
                            /> &nbsp;&nbsp;
                                <InputButton
                                    type="button"
                                    onClick={resetForm}
                                    label="Cancel"
                                    icon="cancel"
                                    additionalClasses="btn-small blue"
                                />
                            </div>
                        )}
                    </form>
                </div>
               <br></br>
                <div>
                    <div className="row">
                        {items.map(item => (
                            <div className="col s6 m4 l2" key={item.inventoryid}>
                                <MaterializeCard
                                    title=""
                                    image={item.image}
                                    itemcode={item.label}
                                    quantity={item.quantity}
                                    boxno={item.boxno}
                                    price={item.price}
                                    sellingprice={item.sellingprice}
                                    editClick={() => handleEdit(item.inventoryid)}
                                    deleteClick={() => handleDelete(item.inventoryid)}
                                    deleteIcon="delete"
                                    editIcon="edit"
                                    onImageClick={() => handleImageClick(item.image)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ModalBox
                id="confirm-modal"
                title={modalConfig.title}
                content={modalConfig.content}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            <div id="price-adjustment-modal" className="modal">
                <div className="modal-content">
                    <h4>Adjust Price</h4>
                    <p>Price: {priceAdjustmentModal.price}</p>
                    <label>
                        <input
                            type="checkbox"
                            checked={priceAdjustmentModal.checkboxChecked}
                            onChange={handleCheckboxChange}
                        />
                        <span>Plus 320</span>
                    </label>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '150px', marginTop: '10px' }}>
                        <span onClick={() => handleAdjustment(100)}>+ 100</span>
                        <span onClick={() => handleAdjustment(-100)}>- 100</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '150px', marginTop: '10px' }}>
                        <span onClick={() => handleAdjustment(50)}>+ 50</span>
                        <span onClick={() => handleAdjustment(-50)}>- 50</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '150px', marginTop: '10px' }}>
                        <span onClick={() => handleAdjustment(25)}>+ 25</span>
                        <span onClick={() => handleAdjustment(-25)}>- 25</span>
                    </div>
                    <p>Adjusted Price: {priceAdjustmentModal.adjustedPrice.toFixed(2)}</p>
                    <p>Calculations:</p>
                    <ul>
                        {priceAdjustmentModal.calculations.map((calc, index) => (
                            <li key={index}>{calc}</li>
                        ))}
                    </ul>
                    <button onClick={resetPriceAdjustment} className="btn">Reset</button>
                </div>
                <div className="modal-footer">
                    <button className="modal-close btn-flat" onClick={handlePriceAdjustmentCancel}>Cancel</button>
                    <button className="modal-close btn-flat" onClick={handlePriceAdjustmentConfirm}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default InventoryPage;
