import React, { useEffect } from 'react';

const GooglePayButton = ({ cartItems = [] }) => {
    useEffect(() => {
        // Check if the Google Pay API is available
        const checkGooglePayAvailability = () => {
            if (window.google && window.google.payments && window.google.payments.api) {
                const paymentsClient = new window.google.payments.api.PaymentsClient({ environment: 'TEST' });
                const button = paymentsClient.createButton({
                    onClick: onGooglePayButtonClicked
                });
                const googlePayButtonContainer = document.getElementById('google-pay-button');
                if (googlePayButtonContainer) {
                    googlePayButtonContainer.innerHTML = ''; // Clear any existing content to prevent multiple buttons
                    googlePayButtonContainer.appendChild(button);
                }
            } else {
                console.error("Google Pay API not available.");
            }
        };

        // Ensure the script is loaded before running the code
        if (window.google && window.google.payments && window.google.payments.api) {
            checkGooglePayAvailability();
        } else {
            const script = document.createElement('script');
            script.src = "https://pay.google.com/gp/p/js/pay.js";
            script.async = true;
            script.onload = checkGooglePayAvailability;
            document.head.appendChild(script);
        }
    }, []);

    const onGooglePayButtonClicked = () => {
        const paymentsClient = new window.google.payments.api.PaymentsClient({ environment: 'TEST' });

        const paymentDataRequest = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [{
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA']
                },
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'example',  // Replace with your gateway
                        gatewayMerchantId: 'exampleMerchantId'  // Replace with your merchant ID
                    }
                }
            }],
            merchantInfo: {
                merchantId: '01234567890123456789',  // Replace with your merchant ID
                merchantName: 'Example Merchant'  // Replace with your merchant name
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPrice: calculateTotalPrice(),
                currencyCode: 'INR',
                countryCode: 'IN'
            }
        };

        paymentsClient.loadPaymentData(paymentDataRequest)
            .then(paymentData => {
                // Handle the response
                processPayment(paymentData);
            })
            .catch(error => {
                console.error('Google Pay payment failed', error);
            });
    };

    const calculateTotalPrice = () => {
        // Calculate the total price from cartItems
        return cartItems.reduce((total, item) => {
            return total + parseFloat(item.sellingprice || 0); // Ensure sellingprice is treated as a number
        }, 0).toFixed(2);
    };

    const processPayment = (paymentData) => {
        // Send payment data to your server for processing
        console.log('Payment Data:', paymentData);

        // Example: Handle the payment response here
        // This is where you would send the payment data to your backend to process the transaction
        alert('Payment Successful!');
    };

    return <div id="google-pay-button"></div>;
};

export default GooglePayButton;
